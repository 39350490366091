import React, { useState } from "react"
import "./style.scss"
import OutsourcingIMG from "../../../../../assets/img/outsourcing-person.png"
import { Grow } from "@material-ui/core"
import VizSensor from "react-visibility-sensor"
import Person from "@mui/icons-material/Person"
import Groups from "@mui/icons-material/Groups"
import PhoneIphone from "@mui/icons-material/PhoneIphone"

const OutsourcingWhyPage = () => {
  let [active, setActive] = useState(false)

  return (
    <div className="outrsoucing-why-page">
      <h1 className="why-title">Por Que?</h1>
      <p className="why-subtitle">Lorem ipsum dolor sit amet.</p>

      <div className="why-content-wrapper">
        <div className="why-description">
          <VizSensor
            onChange={isVisible => {
              setActive(isVisible)
            }}
          >
            <div className="why-content-title">Terceirize sua T.I.</div>
          </VizSensor>
          <div className="why-content-subtitle">
            Lorem ipsum dolor sit amet.
          </div>
          <div className="why-text">
            Phasellus varius justo metus, ac finibus massa faucibus ut. Duis sit
            amet mauris in nibh ornare interdum. Orci varius natoque penatibus
            et magnis dis parturient montes, nascetur ridiculus mus. Phasellus
            ut rutrum lorem. Ut elit risus, pellentesque ut lacus eget, tempus
            venenatis lacus.
          </div>
          <div className="why-icons">
            <div className="why-icon">
              <Person />
              <div className="icon-title">Desenvolvedor</div>
              <div className="icon-text">
                Sed faucibus erat et ultrices mattis. Nullam scelerisque, nibh a
                gravida eleifend, tellus enim convallis urna, non suscipit ante
                tortor ut quam. Aenean diam neque.
              </div>
            </div>
            <div className="why-icon">
              <Groups />
              <div className="icon-title">Scrum Master</div>
              <div className="icon-text">
                Sed tempus ante a facilisis tincidunt. Duis semper dictum augue
                vel tempus. Pellentesque eu turpis porta, vestibulum dui ut,
                mollis eros.
              </div>
            </div>
            <div className="why-icon">
              <PhoneIphone />
              <div className="icon-title">Designer UX/UI</div>
              <div className="icon-text">
                Sed tristique dignissim massa, eu rutrum enim ultricies
                elementum. Etiam ac lacus sapien. Sed suscipit tincidunt justo
                quis.
              </div>
            </div>
          </div>
        </div>

        <Grow
          in={active}
          style={{ transformOrigin: "0 0 0" }}
          {...(active ? { timeout: 1000 } : {})}
        >
          <div className="why-image">
            <img src={OutsourcingIMG} alt="automação" />
          </div>
        </Grow>
      </div>
    </div>
  )
}

export default OutsourcingWhyPage
